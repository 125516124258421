import * as React from "react"

import Seo from "../../components/seo"
import ReduxWrapper from "../../redux/reduxWrapper"
import RequestPassword from "../../components/ResetPassword/requestPassword"

const RequestPage = props => {
  return (
    <React.Fragment>
      <Seo title="Reset Password" />
      <RequestPassword location={props.location} />
    </React.Fragment>
  )
}

const WrappedPage = props => <ReduxWrapper element={<RequestPage {...props} />} />
export default WrappedPage
