import React, { useState } from "react"
import { Link, navigate } from "gatsby"
import Axios from "axios"

import ChatBox from "./chatBox"

const RequestPassword = ({ location }) => {
  const [username, setUsername] = useState(""),
    [loading, setLoading] = useState(false)

  const params = new URLSearchParams(location.search)
  const chat = params.get("chat")

  const handleRequestPassword = (event, username) => {
    if (!username) {
      return false
    }

    event.preventDefault()

    setLoading(true)

    Axios.post(`${process.env.GATSBY_API_URL}/reset-password/request`, { email: username })
      .then(function (response) {
        navigate("/reset-password/requested")
      })
      .catch(function (error) {})
  }

  return (
    <React.Fragment>
      <section className={chat === "newuserpwreset" ? "resetSection" : "loginSection"}>
        <div className="loginContainer">
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <form
                onSubmit={event => {
                  handleRequestPassword(event, username)
                }}
                id="req-reset-pwd"
              >
                <div className="form-group">
                  <h2 className="text-center">Password Reset</h2>
                  <p>What's the email on your SwiftCloud account?</p>
                </div>
                <div className="input-group mb-3">
                  <span
                    className="input-group-text"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Email"
                  >
                    @
                  </span>
                  <input
                    id="username"
                    className="form-control formControlInput"
                    type="email"
                    tabIndex="1"
                    value={username}
                    placeholder="Email"
                    required
                    onChange={event => {
                      setUsername(event.target.value)
                    }}
                  />
                  <label id="username-error" className="error d-none" htmlFor="username" />
                </div>
                <div className="mb-3">
                  <button
                    type="submit"
                    disabled={loading}
                    id="loginModalButton"
                    className="btn btn-primary w-100"
                  >
                    <i className="bi bi-arrow-right-circle" /> Send Reset Password Link{" "}
                    {loading && <i className="spinner-border spinner-border-sm" />}
                  </button>
                </div>
              </form>
              <hr />
              <div className="text-center">
                <p>
                  Already hooked up?{" "}
                  <Link to="/login" className="btn btn-secondary">
                    <i className="bi bi-check2" /> LOGIN
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        {chat === "newuserpwreset" && <ChatBox />}
      </section>
    </React.Fragment>
  )
}

export default RequestPassword
