import React, { useState } from "react"
import "./chatPublic.css"

const ChatBox = () => {
  const [showChatBox, setShowChatBox] = useState(true)

  return (
    <>
      <div
        className="SCCP_chatDockSwitch"
        onClick={() => {
          setShowChatBox(true)
        }}
      >
        <input type="checkbox" id="SCCP_chatDockSwitch" defaultChecked={showChatBox} />
        <label htmlFor="SCCP_chatDockSwitch" className="checkmark" />
      </div>
      <div
        className={`SCCP_chatDockNonSocialAssetOnly SCCP_publicChatDock ${
          showChatBox ? " SCCP_publicChatDockActive " : ""
        }`}
      >
        <div className="SCCP_chatDockHeader">
          <div>
            <div className="SCCP_chatDockHeaderUserInfo">
              <button
                className="btn btn-default dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="SCCP_chatDockInputOptIcon">
                  <img src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/swift_cloud_flag.jpg`} alt="" />
                </span>
                <span className="SCCP_chatDockInputOptName">SwiftCloud System Messages</span>
              </button>
              {/* <ul className="dropdown-menu SCCP_chatDockInputOptList">
                <li>
                  <a className="dropdown-item" href="#">
                    <span className="SCCP_chatDockInputOptListIcon">
                      <i className="bi bi-robot" />
                    </span>
                    <span className="SCCP_chatDockInputOptListName">Chatbot / FAQs</span>
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    <span className="SCCP_chatDockInputOptListIcon">
                      <img src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/roger.jpg`} />
                    </span>
                    <span className="SCCP_chatDockInputOptListName">Sales (Human)</span>
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    <span className="SCCP_chatDockInputOptListIcon">
                      <i className="bi bi-life-preserver" />
                    </span>
                    <span className="SCCP_chatDockInputOptListName">Support</span>
                  </a>
                </li>
              </ul> */}
            </div>
            <a
              href="#"
              className="SCCP_btnCloseChatDock"
              data-bs-toggle="tooltip"
              title="Close"
              data-bs-original-title="Close"
              aria-label="Close"
              onClick={() => {
                setShowChatBox(false)
              }}
            >
              <i className="bi bi-x-lg" />
            </a>
          </div>
          <div className="dropdown SCCP_chatDockInputOpt">
            <button
              className="btn btn-default dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span className="SCCP_chatDockInputOptIcon">
                <img src={`${process.env.GATSBY_WRANGLER_URL + "StaticImages/avatar.gif"}`} />
              </span>
              <span className="SCCP_chatDockInputOptName">Guest</span>
            </button>
            <ul className="dropdown-menu SCCP_chatDockInputOptList">
              <li>
                <a className="dropdown-item" href="#">
                  <span className="SCCP_chatDockInputOptListIcon">
                    <img src={`${process.env.GATSBY_WRANGLER_URL + "StaticImages/avatar.gif"}`} />
                  </span>
                  <span className="SCCP_chatDockInputOptListName">Guest</span>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div
          className="SCCP_chatDockHistoryContainer mCustomScrollbar _mCS_1 mCS_no_scrollbar"
          style={{ height: "400px", overflowY: "scroll" }}
        >
          <div id="mCSB_1" className="mCustomScrollBox mCS-dark mCSB_vertical mCSB_inside" tabIndex="0">
            <div id="mCSB_1_container" className="mCSB_container mCS_y_hidden mCS_no_scrollbar_y" dir="ltr">
              <ul className="chat">
                <li className="other">
                  <div className="chat-msg">
                    <div className="avatar">
                      <a
                        href="#"
                        className="d-block"
                        data-bs-toggle="tooltip"
                        title=""
                        data-bs-original-title="SwiftCloud"
                        aria-label="SwiftCloud"
                      >
                        <img
                          src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/swift_cloud_flag.jpg`}
                          className="mCS_img_loaded"
                        />
                      </a>
                    </div>
                    <div className="msg">
                      <p>Thanks & Welcome! We have received your payment or subscription.</p>
                    </div>
                  </div>
                </li>
                <li className="other">
                  <div className="chat-msg">
                    <div className="avatar">
                      <a
                        href="#"
                        className="d-block"
                        data-bs-toggle="tooltip"
                        title=""
                        data-bs-original-title="SwiftCloud"
                        aria-label="SwiftCloud"
                      >
                        <img
                          src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/swift_cloud_flag.jpg`}
                          className="mCS_img_loaded"
                        />
                      </a>
                    </div>
                    <div className="msg">
                      <p>We have your account created.</p>
                    </div>
                  </div>
                </li>
                <li className="other">
                  <div className="chat-msg">
                    <div className="avatar">
                      <a
                        href="#"
                        className="d-block"
                        data-bs-toggle="tooltip"
                        title=""
                        data-bs-original-title="SwiftCloud"
                        aria-label="SwiftCloud"
                      >
                        <img
                          src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/swift_cloud_flag.jpg`}
                          className="mCS_img_loaded"
                        />
                      </a>
                    </div>
                    <div className="msg">
                      <p>Please reset your password and we will get you started.</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div
              id="mCSB_1_scrollbar_vertical"
              className="mCSB_scrollTools mCSB_1_scrollbar mCS-dark mCSB_scrollTools_vertical "
              style={{ display: "block" }}
            >
              <div className="mCSB_draggerContainer">
                <div
                  id="mCSB_1_dragger_vertical"
                  className="mCSB_dragger"
                  style={{
                    position: "absolute",
                    minHeight: "30px",
                    height: "26px",
                    top: "0px",
                    display: "block",
                    maxHeight: "56.7969px",
                  }}
                  onContextMenu="return false"
                >
                  <div className="mCSB_dragger_bar" style={{ lineHeight: "30px" }} />
                  <div className="mCSB_draggerRail" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ChatBox
